export default {
  Headers: {
    tab1: '首頁',
    tab2: '教程',
    tab3: "商務合作",
    btntext: '掃碼下載',
    tabT1: 'PayBet-首頁',
    tabT2: 'PayBet-教程',
    tabT3: "PayBet-商務合作",
  },
  home: {
    title: '首頁',
    dText1: 'Apple下載',
    dText2: 'Android下載',
    dText3: 'Web訪問',
    text1: '飆速加值 暢行遊戲',
    text2_1: '超級快充',
    text2_2: '極致體驗',
    text3: '不受時間及國界限制，無論何時何地都能完成交易安全高效，不可追蹤，',
    text3_1: '不受時間及國界限制，無論何時何地都能完成交易安全高效，不可追蹤，無法凍結',
    text4: '無法凍結',
    text5: '友情提示:親愛的PayBet用户沒有收到款項，切記不要打幣 如有操作失誤，損失自行承擔',
    smText1: '私密貨幣',
    smText2: '為您提供高安全性的交易方式免除使用法幣',
    smText3: '總資產',
    smText4: '快捷買幣',
    smText5: '掛單出售',
    smText6: '我的掛單',
    smText7: '整單出售',
    smText8: '立即購買',
    wdText1: "穩定貨幣",
    wdText2: "匯率穩定性高，恆定全球法幣幣值",
    wdText3: "熱點功能",
    wdText4: "收款",
    wdText5: "付款",
    wdText6: "轉賬",
    jdText1: "操作簡單",
    jdText2: "下單、付款、打幣，交易只需要三步即可完成",
    jdText3: "交易成功",
    jdText4: "您已成功出售",
    jdText5: "當前可用餘額",
    jdText6: "交易編號",
    jdText7: "已成交",
    kjText1: "跨境交易",
    kjText2: "24/7交易無國界，加值一秒完成",
    kjText3: "跨境交易",
    kjText4: "收款人姓名",
    kjText5: "收款方式",
    kjText6: "收款賬號",
    kjText7: "支付寶",
    kjText8: "買方付款信息",
    kjText9: "付款人姓名",
    kjText10: "付款方式",
    kjText11: "付款賬號",
    kjText12: "王*爾",
    kjText13: "李*博",
    yyText1: "全面應用",
    yyText2: "極致體驗，服務更豐富",
    yyText3: "銀行卡號",
    qqText1: "全球部署服務器",
    qqText2: "不受時間及國界限制，無論何時何地都能完成交易，加值一秒完成",
  },
  footer: {
    text1: "下載PayBet開啓全新之旅",
    text2: "不受時間及國界限制，無論何時何地都能完成交易安全高效，不可追蹤，無法凍結",
    text3: "投訴郵箱",
    text4: "查詢電郵"
  },
  cooperate: {
    topText1: '我們的服務',
    topText2: "面向商户提供全方位的聚合支付服務，滿足商户線上一體化的支付需求。依託在支付領域深耕多年積累的技術與服務經驗，為客户搭建全面賬户體系，代付、代收、賬務管理等賬户管理服務。",
    topText3: "Telegram客服",
    cItemText1: "資金安全",
    cItemText2: "無法凍結，多道風控，嚴格審批，給您的資金百分百安全",
    cItemText3: "智能識別",
    cItemText4: "用户行為",
    cItemText5: "身份鑑定",
    tcItemText1: "賬務清晰",
    tcItemText2: "賬務完整、清晰、明瞭，詳細掌握資金來龍去脈",
    tcItemText3: "資金秒到",
    tcItemText4: "收支明細",
    tcItemText5: "資金賬單",
  },
  course: {
    title1: "實名認證",
    title2: "收付款",
    title3: "賣幣教程",
    title4: "買幣教程",
    title5: "加值教程",
    title6: "提幣教程"
  },
  iosModal: {
    "text1": "App在桌面下載完成後，必需按以下步驟操作，設置信任後才能正常使用:",
    "text2": "返回手機桌面打開i0S系統設置",
    "text3": "打開",
    "text4": "設置-一般-VPN與設備管理",
    "text5": "找到剛安裝的應用，點擊設置信任"
  }
  
}