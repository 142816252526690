<template>
  <div class="box-wrap" :class="{ isM: is750 }">
    <div class="box-css">
      <Header @emit-lang="settile" active="home" />
      <div class="banner-box">
        <div class="top-text">
          <div class="title-one">{{ $t("home.text1") }}</div>
          <div class="title-two" :class="{ isAncient: langName == 'zh-Hant' }">
            <span>{{ $t("home.text2_1") }}</span>
            <span>{{ $t("home.text2_2") }}</span>
          </div>
          <div class="note-top">
            <template v-if="!is750">
              <span>{{ $t("home.text3") }}</span>
              <p>{{ $t("home.text4") }}</p>
            </template>
            <template v-else>
              {{ $t("home.text3_1") }}
            </template>
          </div>
        </div>
        <div class="btns-item">
          <a v-if="config?.iosUrl" download="PayBet" :href="config?.iosUrl || ''" class="btn-css" @click.stop="iosModal = true">
            <img src="@/assets/img/home/apple-logo.png" alt="" />
            <span>{{ $t("home.dText1") }}</span>
          </a>
          <a
            v-if="config?.appUrl"
            download="PayBet"
            :href="
              config?.appUrl ||
              'https://www.betpay.vip/app/Betpay_V1.2.0_HW_Prod_HK.apk'
            "
            class="btn-css"
          >
            <img src="@/assets/img/home/android-logo.png" alt="" />
            <span>{{ $t("home.dText2") }}</span>
          </a>
          <a
            :href="config?.pageUrl || 'https://page.betpay.cc'"
            target="_blank"
            class="btn-css"
          >
            <img src="@/assets/img/home/web-logo.png" alt="" />
            <span>{{ $t("home.dText3") }}</span>
          </a>
        </div>
        <p class="note-text">{{ $t("home.text5") }}</p>
      </div>
      <div class="content-box">
        <div class="content-item">
          <div class="item-left">
            <div class="index-css"><span>01</span></div>
            <div class="item-title">{{ $t("home.smText1") }}</div>
            <div class="item-note">{{ $t("home.smText2") }}</div>
          </div>
          <div class="item-right item-right-one">
            <div class="img-one">
              <img src="@/assets/img/home/image-one.png" alt="" />
            </div>
            <div class="right-item-one">
              <div class="item-top">
                <p>
                  {{ $t("home.smText3") }}（B）:
                  <CountTo
                    :duration="2000"
                    :decimals="2"
                    :endVal="124456789"
                  /><span></span>
                </p>
                <i class="icon-one"></i>
              </div>
              <div class="item-bottom" :class="[langName]">
                <div class="bt-list">
                  <img src="@/assets/img/home/bt-icon-1.png" alt="" />
                  <p>{{ $t("home.smText4") }}</p>
                </div>
                <div class="bt-list">
                  <img src="@/assets/img/home/bt-icon-2.png" alt="" />
                  <p>{{ $t("home.smText5") }}</p>
                </div>
                <div class="bt-list">
                  <img src="@/assets/img/home/bt-icon-3.png" alt="" />
                  <p>{{ $t("home.smText6") }}</p>
                </div>
              </div>
            </div>
            <div class="right-item-two">
              <div class="two-top"></div>
              <div class="two-bottom">
                <div class="tb-left">
                  <div class="l-top">{{ $t("home.smText7") }}</div>
                  <div class="l-bottom">200 - 10,000 B</div>
                </div>
                <div class="tb-right">{{ $t("home.smText8") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left" v-show="is750">
            <div class="index-css"><span>02</span></div>
            <div class="item-title">{{ $t("home.wdText1") }}</div>
            <div class="item-note">{{ $t("home.wdText2") }}</div>
          </div>
          <div class="item-right item-right-two">
            <div class="two-item-top">
              <div class="tt-title">{{ $t("home.wdText3") }}</div>
              <div class="tt-item">
                <div class="item-list" v-for="i of 5" :key="'list_' + i"></div>
              </div>
            </div>
            <div class="two-item-bottom">
              <div class="tb-top">
                <div class="tbt-left">
                  <p>{{ $t("home.smText3") }}（ B ）</p>
                  <span class="tbt-icon"></span>
                </div>
                <div class="tbt-right"><i class="tbt-right-icon"></i></div>
              </div>
              <div class="tb-center">
                <CountTo
                  :duration="2000"
                  :decimals="2"
                  :endVal="88888888"
                /><span></span>
              </div>
              <div class="tb-bottom" :class="[langName]">
                <div class="tbt-item">
                  <img src="@/assets/img/home/two-3.png" alt="" />
                  <span>{{ $t("home.wdText4") }}</span>
                </div>
                <div class="tbt-item">
                  <img src="@/assets/img/home/two-4.png" alt="" />
                  <span>{{ $t("home.wdText5") }}</span>
                </div>
                <div class="tbt-item">
                  <img src="@/assets/img/home/two-5.png" alt="" />
                  <span>{{ $t("home.wdText6") }}</span>
                </div>
                <div class="tbt-item">
                  <img src="@/assets/img/home/two-6.png" alt="" />
                  <span>{{ $t("home.smText5") }}</span>
                </div>
              </div>
            </div>
            <div class="two-item-null"></div>
          </div>
          <div class="item-left" v-show="!is750">
            <div class="index-css"><span>02</span></div>
            <div class="item-title">{{ $t("home.wdText1") }}</div>
            <div class="item-note">{{ $t("home.wdText2") }}</div>
          </div>
        </div>
        <div class="content-item  content-item-three">
          <div class="item-left">
            <div class="index-css"><span>03</span></div>
            <div class="item-title">{{ $t("home.jdText1") }}</div>
            <div class="item-note">
              {{ $t("home.jdText2") }}
            </div>
          </div>
          <div class="item-right item-right-one">
            <div class="trt-item">
              <div class="trt-left">
                <p>{{ $t("home.jdText3") }}</p>
                <span> {{ $t("home.jdText4") }} 888,888,00 B</span>
              </div>
              <div class="trt-right">
                <img src="@/assets/img/home/check-icon.png" alt="" />
              </div>
            </div>
            <div class="trc-item">
              <div class="trct">
                <h3>+ 888,98.00</h3>
                <p>{{ $t("home.jdText5") }}：125,000,200 B</p>
              </div>
              <div class="trcb">{{ $t("home.jdText6") }}：20308081513888</div>
            </div>
            <div class="trb-item">
              <div class="trb-line">
                <span></span>
              </div>
              <div class="trbb">
                <h3>{{ $t("home.jdText7") }}</h3>
                <p>1,002,500.00 B</p>
              </div>
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left" v-show="is750">
            <div class="index-css"><span>04</span></div>
            <div class="item-title">{{ $t("home.kjText1") }}</div>
            <div class="item-note">{{ $t("home.kjText2") }}</div>
          </div>
          <div class="item-right item-right-two">
            <div class="four-item">
              <div class="four-list">
                <div class="title-text">{{ $t("home.kjText3") }}</div>
                <div class="item-list">
                  <div class="lable">{{ $t("home.kjText4") }}</div>
                  <div class="value">{{ $t("home.kjText12") }}</div>
                </div>
                <div class="item-list">
                  <div class="lable">{{ $t("home.kjText5") }}</div>
                  <div class="value">{{ $t("home.kjText7") }}</div>
                </div>
                <div class="item-list">
                  <div class="lable">{{ $t("home.kjText6") }}</div>
                  <div class="value">188****9988</div>
                </div>
              </div>
              <div class="four-list">
                <div class="title-text">{{ $t("home.kjText8") }}</div>
                <div class="item-list">
                  <div class="lable">{{ $t("home.kjText9") }}</div>
                  <div class="value">{{ $t("home.kjText13") }}</div>
                </div>
                <div class="item-list">
                  <div class="lable">{{ $t("home.kjText10") }}</div>
                  <div class="value">{{ $t("home.kjText7") }}</div>
                </div>
                <div class="item-list">
                  <div class="lable">{{ $t("home.kjText11") }}</div>
                  <div>
                    <div class="value">138****8888</div>
                    <div class="cpoy-icon"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-left" v-show="!is750">
            <div class="index-css"><span>04</span></div>
            <div class="item-title">{{ $t("home.kjText1") }}</div>
            <div class="item-note">{{ $t("home.kjText2") }}</div>
          </div>
        </div>
        <div class="content-item phone-bg">
          <div class="phone-text">
            <h3>{{ $t("home.yyText1") }}</h3>
            <p>{{ $t("home.yyText2") }}</p>
          </div>
          <div class="phone-text-2">
            <h3>{{ $t("home.yyText3") }}</h3>
          </div>
        </div>
        <div class="content-item layout-bg">
          <div class="layout-text">
            <div class="item-title">{{ $t("home.qqText1") }}</div>
            <div class="item-note">
              {{ $t("home.qqText2") }}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
     <ios-modal v-model="iosModal"/>
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import { onChangeWidth } from "@/util/onChangeWidth.js";
import { mapGetters } from "vuex";
export default {
  name: "home",
  data() {
    return {
      loading: true,
      isLoading: false,
      showGoTop: false,
      iosModal: false
    };
  },
  components: {
    Header: () => import("@/components/header.vue"),
    Footer: () => import("@/components/footer.vue"),
    CountTo,
    IosModal: ()=> import('@/components/iosModal.vue')
  },
  created() {
    onChangeWidth(this);
  },
  mounted() {
    this.settile();
    console.log(this.config);
  },
  computed: {
    ...mapGetters(["lang", "cWidth", "config"]),
    langName() {
      return this.lang?.value || "zh-Hant";
    },
    is750() {
      let flag = false;
      if (this.cWidth < 750) {
        flag = true;
      }
      return flag;
    },
  },
  methods: {
    settile() {
      document.title = this.$t("Headers.tabT1");
    },
  },
};
</script>
<style lang="scss" scoped>
.banner-box {
  padding: 6% 12%;
  width: 100%;
  box-sizing: border-box;
  background: url("~@/assets/img/home/content-bg.png") no-repeat right bottom;
  background-size: 50%;
  .title-one {
    color: #1170ff;
    font-family: Alibaba PuHuiTi;
    font-size: 0.16rem;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
  }
  .title-two {
    display: flex;
    font-family: LogoSC Unbounded Sans, Alibaba PuHuiTi, PingFang SC,
      "Microsoft YaHei", SimHei;
    padding-top: 0.2rem;
    font-size: 0.6rem;
    font-weight: 400;
    flex-wrap: wrap;
    line-height: 100%; /* 68px */
    background: linear-gradient(
      126deg,
      #eda1ab 0%,
      #c4c2ff 51.08%,
      #eda1ab 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    &.isAncient {
      font-family: PingFang SC, "Microsoft YaHei", SimHei;
      font-weight: 900;
    }
    span {
      margin-right: 0.2rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .note-top {
    padding-top: 0.26rem;
    color: #b1c8de;
    font-family: Alibaba PuHuiTi;
    font-size: 0.16rem;
    font-weight: 600;
    line-height: 150%; /* 19.2px */
  }
  .btns-item {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.6rem;
    .btn-css {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      min-width: 1.88rem;
      height: 0.66rem;
      border-radius: 0.2rem;
      border: 1px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;
      margin-right: 0.2rem;
      margin-top: 0.2rem;
      padding: 0.12rem 0.2rem;
      color: #fff;
      font-family: Alibaba PuHuiTi;
      font-size: 0.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
      &.active {
        background: linear-gradient(208deg, #3a65f1 0%, #1848e1 100%);
        border-color: #3a65f1;
      }
      img {
        display: block;
        width: 0.42rem;
        height: 0.42rem;
      }
    }
  }
  .note-text {
    padding-top: 0.4rem;
    color: #fff;
    font-family: Alibaba PuHuiTi;
    font-size: 0.14rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5; /* 100% */
  }
}
.content-box {
  width: 100%;
  padding: 0 14%;
  overflow: hidden;
  .content-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10% 0;
    .item-left {
      max-width: 60%;
      .index-css {
        width: 0.48rem;
        height: 0.48rem;
        border-radius: 50%;
        background: #293ce1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: NIKElogo;
        font-size: 0.24rem;
        font-style: italic;
        font-weight: 800;
        span {
          transform: translateY(0.04rem);
        }
      }
      .item-title {
        padding-top: 0.2rem;
        font-family: Alibaba PuHuiTi;
        font-size: 0.48rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 67.2px */
        background: linear-gradient(
          135deg,
          #92ddfe 20.23%,
          #fff 50%,
          #bc96fc 84.84%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .item-note {
        padding-top: 0.1rem;
        color: #fff;
        font-family: Alibaba PuHuiTi;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 33.6px */
        word-break: break-all;
      }
    }
    .item-right {
      position: relative;
      .img-one {
        padding-right: 0.1rem;
        margin-bottom: 0.6rem;
        img {
          display: block;
          width: 3.06rem;
          height: 0.62rem;
        }
      }
      .right-item-one {
        position: absolute;
        top: 0.5rem;
        right: 0.6rem;
        width: 3.43rem;
        height: 1.06rem;
        box-sizing: content-box;
        padding: 0.16rem;
        border-radius: 0.12rem;
        background: linear-gradient(132deg, #f6faff 0%, #fff 100%);
        .item-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0.16rem;
          border-bottom: 1px solid #f3f5f9;
          p {
            color: #999;
            font-family: PingFang SC;
            font-size: 0.14rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.2rem; /* 140% */
            span {
              color: #333;
              font-family: Montserrat;
              font-size: 0.18rem;
              font-style: normal;
              font-weight: 600;
              padding-left: 0.1rem;
            }
          }
          .icon-one {
            width: 0.2rem;
            height: 0.2rem;
            background: url("~@/assets/img/home/icon-one.png") no-repeat center;
            background-size: contain;
          }
        }
        .item-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.16rem 0.32rem 0;
          &.pt-br,  &.es {
            padding: 0.06rem 0 0;
            text-align: center;
          }
          .bt-list {
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
              display: block;
              width: 0.26rem;
              height: 0.26rem;
            }
            p {
              padding-top: 0.08rem;
              color: #666;
              font-family: PingFang SC;
              font-size: 0.14rem;
              font-style: normal;
              font-weight: 400;
              line-height: 0.2rem; /* 140% */
            }
          }
        }
      }
      .right-item-two {
        width: 3.43rem;
        height: 1.59rem;
        border-radius: 0.12rem;
        background: #f1f6ff;
        padding: 0.16rem;
        .two-top {
          margin: 0.2rem 0;
          height: 0.2rem;
          background: #e7f0ff;
        }
        .two-bottom {
          padding-top: 0.16rem;
          border-top: 1px solid #e7f0ff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .tb-left {
            .l-top {
              color: #9a9a9a;
              font-family: PingFang SC;
              font-size: 0.12rem;
              font-style: normal;
              font-weight: 300;
              line-height: 0.2rem; /* 163.333% */
            }
            .l-bottom {
              padding-top: 0.06rem;
              color: #333;
              font-family: Montserrat;
              font-size: 0.18rem;
              font-style: normal;
              font-weight: 600;
              line-height: 0.2rem; /* 108.889% */
            }
          }
          .tb-right {
            transform: translateY(0.04rem);
            display: inline-flex;
            padding: 0.08rem 0.16rem;
            justify-content: center;
            align-items: center;
            border-radius: 0.2rem;
            background: #1370fb;
            color: #fff;
            font-family: PingFang SC;
            font-size: 0.14rem;
            font-style: normal;
            font-weight: 500;
            line-height: 0.2rem; /* 140% */
          }
        }
      }
      .two-item-top {
        width: 3.07rem;
        height: 1rem;
        padding: 0.2rem;
        border-radius: 0.12rem;
        background: linear-gradient(140deg, #f5f8ff 0%, #f2f5ff 100%);
        .tt-title {
          color: #353850;
          font-family: Montserrat;
          font-size: 0.12rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1; /* 100% */
        }
        .tt-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 0.16rem;
          .item-list {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            background: #fff;
            &:nth-child(2) {
              background: #fefff2;
            }
            &:nth-child(3) {
              background: #b3d3fa;
            }
            &:nth-child(5) {
              background: #b5ece7;
            }
          }
        }
      }
      .two-item-bottom {
        position: absolute;
        top: 0.64rem;
        left: 0.5rem;
        width: 3.43rem;
        padding: 0.16rem;
        border-radius: 0.16rem;
        box-sizing: border-box;
        background: linear-gradient(317deg, #3280fe 0%, #1571fd 100%);
        .tb-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .tbt-left {
            display: flex;
            align-items: center;
            p {
              color: #fff;
              font-family: PingFang SC;
              font-size: 0.12rem;
              font-style: normal;
              font-weight: 400;
              line-height: 0.2rem; /* 166.667% */
            }
            .tbt-icon {
              width: 0.2rem;
              height: 0.2rem;
              margin-left: 0.1rem;
              background: url("~@/assets/img/home/two-1.png") no-repeat center;
              background-size: 100%;
            }
          }
          .tbt-right {
            .tbt-right-icon {
              display: block;
              width: 0.2rem;
              height: 0.2rem;
              background: url("~@/assets/img/home/two-2.png") no-repeat center;
              background-size: contain;
            }
          }
        }
        .tb-center {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.2rem 0;
          span {
            color: #fff;
            text-align: center;
            font-family: Montserrat;
            font-size: 0.3rem;
            font-style: normal;
            font-weight: 600;
            line-height: 0.36rem; /* 120% */
          }
        }
        .tb-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.2rem;
          &.pt-br, &.es {
            padding: 0;
            align-items: flex-start;
            text-align: center;
          }
          .tbt-item {
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
              width: 0.24rem;
              height: 0.24rem;
            }
            span {
              padding-top: 0.08rem;
              color: #fff;
              font-family: PingFang SC;
              font-size: 0.14rem;
              font-style: normal;
              font-weight: 400;
              line-height: 0.2rem; /* 142.857% */
            }
          }
        }
      }
      .trt-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 3.37rem;
        padding: 0.24rem 0.16rem;
        border-radius: 0.12rem;
        background: #f1f6ff;
        box-sizing: border-box;
        .trt-left {
          p {
            color: #166ffb;
            font-family: PingFang SC;
            font-size: 0.18rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1; /* 94.444% */
          }
          span {
            padding-top: 0.16rem;
            display: block;
            color: #ababab;
            font-family: PingFang SC;
            font-size: 0.14rem;
            font-style: normal;
            font-weight: 300;
            line-height: 0.18rem; /* 121.429% */
          }
        }
        .trt-right {
          img {
            display: block;
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }
      .trc-item {
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
        width: 3.43rem;
        height: 1.06rem;
        box-sizing: content-box;
        padding: 0.16rem;
        border-radius: 0.12rem;
        background: linear-gradient(143deg, #f6faff 0%, #fff 100%);
        z-index: 2;
        .trct {
          border-bottom: 1px solid rgba(102, 102, 102, 0.1);
          h3 {
            color: #146ffb;
            font-family: Montserrat;
            font-size: 0.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 0.23rem; /* 116.406% */
          }
          p {
            color: #666;
            font-family: PingFang SC;
            font-size: 0.14rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.2rem; /* 140% */
            padding: 0.16rem 0;
          }
        }
        .trcb {
          padding-top: 0.16rem;
          color: #333;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16.8px; /* 140% */
          opacity: 0.3;
        }
      }
      .trb-item {
        margin-top: 0.4rem;
        width: 3.75rem;
        height: 1.58rem;
        padding: 0.24rem 0.16rem;
        box-sizing: border-box;
        border-radius: 0.12rem;
        background: #146ffb;
        transform: translateX(-0.5rem);
        z-index: 1;
        .trb-line {
          margin-top: 0.7rem;
          height: 0.08rem;
          background: #3d84fe;
          span {
            display: block;
            width: 0.85rem;
            height: 0.08rem;
            background: #92baff;
          }
        }
        .trbb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 0.16rem;
          h3 {
            color: #fff;
            font-family: PingFang SC;
            font-size: 0.14rem;
            font-style: normal;
            font-weight: 300;
            line-height: 0.17rem; /* 121.429% */
          }
          p {
            color: #fff;
            font-family: Montserrat;
            font-size: 0.14rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.17rem; /* 121.429% */
          }
        }
      }
      .four-item {
        width: 3.4rem;
        padding: 0.2rem 0.16rem;
        background: #fff;
        border-radius: 0.12rem;
        box-sizing: border-box;
        .four-list {
          .title-text {
            color: #146ffb;
            font-family: Alibaba PuHuiTi;
            font-size: 0.14rem;
            font-style: normal;
            font-weight: 700;
            line-height: 0.17rem; /* 121.429% */
            padding-bottom: 0.16rem;
            border-bottom: 1px dashed #ccc;
          }
          .item-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0.16rem 0;
            &:last-of-type {
              margin-bottom: 0.22rem;
            }
            .lable {
              color: #999;
              font-family: PingFang SC;
              font-size: 0.12rem;
              font-style: normal;
              font-weight: 400;
              line-height: 0.18rem; /* 141.667% */
            }
            .value {
              color: #333;
              font-family: Montserrat;
              font-size: 0.12rem;
              font-style: normal;
              font-weight: 500;
              line-height: 0.18rem; /* 141.667% */
              letter-spacing: -0.3px;
            }
          }
          &:last-child {
            .item-list:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.phone-bg {
      position: relative;
      width: 100%;
      padding-bottom: 50%;
      background: url("~@/assets/img/home/phone-bg.png") no-repeat center bottom;
      background-size: cover;
      .phone-text {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translateX(-50%);
        text-align: left;
        h3 {
          color: #17181b;
          font-family: PingFang HK;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 11.122px */
        }
        p {
          color: #4b5364;
          font-family: PingFang HK;
          font-size: 0.2rem;
          font-style: normal;
          font-weight: 200;
          line-height: 140%; /* 8.089px */
        }
      }
      .phone-text-2 {
        position: absolute;
        top: 66%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        h3 {
          font-size: 0.18rem;
          color: #fff;
          font-family: Montserrat;
          font-weight: bold;
        }
      }
    }
    &.layout-bg {
      padding-bottom: 50%;
      background: url("~@/assets/img/home/layout-bg.png") no-repeat center;
      background-size: 100%;
      .layout-text {
        width: 100%;
        text-align: center;
        .item-title {
          padding-top: 0.2rem;
          font-family: Alibaba PuHuiTi;
          font-size: 0.48rem;
          font-style: none;
          font-weight: 700;
          line-height: 140%; /* 67.2px */
          background: linear-gradient(
            135deg,
            #92ddfe 20.23%,
            #fff 50%,
            #bc96fc 84.84%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .item-note {
          padding-top: 0.1rem;
          color: #fff;
          font-family: Alibaba PuHuiTi;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 33.6px */
        }
      }
    }
  }
  .content-item-three{
    .item-left {
      max-width: 55%;
    }
  }
}
// 移动端
.isM {
  .item-left {
    max-width: 100% !important;
  }
  .item-right-one {
    margin-top: 0.3rem;
    transform: translateX(0.6rem);
  }
  .item-right-two {
    margin-top: 0.3rem;
  }
  .title-two {
    flex-direction: column;
    word-break: break-all;
    span {
      margin: 0;
      margin-bottom: 0.12rem;
      &:last-child {
        margin: 0;
      }
    }
  }
  .banner-box {
    background-size: 100%;
  }
  .two-item-null {
    height: 2rem;
  }
  .phone-bg.content-item {
    margin-left: -0.75rem;
    width: 100vw;
    padding-bottom: 56%;
    background: #050505 url("~@/assets/img/home/phone-bg.png") no-repeat center
      bottom;
    background-size: cover;
    .phone-text {
      h3 {
        font-size: 0.12rem;
      }
      p {
        font-size: 0.1rem;
      }
    }
  }
  .layout-bg.content-item {
    padding-bottom: 60%;
    margin-left: -0.75rem;
    width: 100vw;
  }
  .phone-text-2 {
    width: 1.32rem !important;
    top: 63% !important;
    text-align: left !important;
    h3 {
      font-size: 0.12rem !important;
    }
  }
}
</style>
