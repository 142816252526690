export default {
  "Headers": {
    "tab1": "Inicio",
    "tab2": "Tutorial",
    "tab3": "Colaboración Comercial",
    "btntext": "Descargar",
    "tabT1": "PayBet-Inicio",
    "tabT2": "PayBet-Tutorial",
    "tabT3": "PayBet-Colaboración Comercial"
  },
  "home": {
    "title": "Inicio",
    "dText1": "Descarga para Apple",
    "dText2": "Descarga para Android",
    "dText3": "Acceso Web",
    "text1": "Aumenta la velocidad, disfruta del juego",
    "text2_1": "Carga ultrarrápida",
    "text2_2": "Experiencia suprema",
    "text3": "Sin restricciones de tiempo ni fronteras, las transacciones son seguras y eficientes en cualquier momento y lugar, sin posibilidad de rastreo,",
    "text3_1": "Sin restricciones de tiempo ni fronteras, las transacciones son seguras y eficientes en cualquier momento y lugar, sin posibilidad de rastreo, sin posibilidad de congelación",
    "text4": "Sin posibilidad de congelación",
    "text5": "Consejo amistoso: Querido usuario de PayBet, no envíe monedas si no ha recibido el pago. Recuerde no realizar transferencias incorrectas; cualquier pérdida será responsabilidad suya.",
    "smText1": "Moneda privada",
    "smText2": "Proporciona un método de transacción altamente seguro y elimina el uso de moneda fiduciaria",
    "smText3": "Activos totales",
    "smText4": "Compra rápida de monedas",
    "smText5": "Venta pendiente",
    "smText6": "Mis ventas pendientes",
    "smText7": "Venta completa",
    "smText8": "Compra ahora",
    "wdText1": "Moneda estable",
    "wdText2": "Alta estabilidad en las tasas de cambio, valor constante en monedas fiduciarias globales",
    "wdText3": "Características destacadas",
    "wdText4": "Recepción de pagos",
    "wdText5": "Realización de pagos",
    "wdText6": "Transferencias",
    "jdText1": "Operación sencilla",
    "jdText2": "Realice pedidos, realice pagos, envíe monedas; la transacción se completa en tres simples pasos",
    "jdText3": "Transacción exitosa",
    "jdText4": "Ha vendido con éxito",
    "jdText5": "Saldo disponible actual",
    "jdText6": "Número de transacción",
    "jdText7": "Completado",
    "kjText1": "Transacciones transfronterizas",
    "kjText2": "Operaciones las 24 horas del día, los 7 días de la semana, sin fronteras; la valorización se completa en un segundo",
    "kjText3": "Información de pago del vendedor",
    "kjText4": "Nombre del receptor",
    "kjText5": "Método de recepción",
    "kjText6": "Número de cuenta del receptor",
    "kjText7": "Pix",
    "kjText8": "Información de pago del comprador",
    "kjText9": "Nombre del pagador",
    "kjText10": "Método de pago",
    "kjText11": "Número de cuenta del pagador",
    "kjText12": "Gabriel * Márquez",
    "kjText13": "Alfredo * Rubalcaba",
    "yyText1": "Aplicación integral",
    "yyText2": "Experiencia definitiva",
    "yyText3": "Número de tarjeta",
    "qqText1": "Implementación global de servidores",
    "qqText2": "Sin restricciones de tiempo ni fronteras, las transacciones se pueden completar en cualquier momento y lugar; la valorización se completa en un segundo"
  },
  "footer": {
    "text1": "Descarga PayBet y comienza una nueva aventura",
    "text2": "Sin restricciones de tiempo ni fronteras, las transacciones son seguras y eficientes en cualquier momento y lugar, sin posibilidad de rastreo, sin posibilidad de congelación",
    "text3": "Correo de quejas",
    "text4": "Correo de Consulta"
  },
  "cooperate": {
    "topText1": "Nuestros servicios",
    "topText2": "Proporcionamos servicios de pago integrados para comerciantes, satisfaciendo las necesidades de pago en línea de los comerciantes. Con base en años de experiencia técnica y de servicio en el campo de pagos, construimos un sistema de cuentas completo para los clientes, con servicios de pago y cobro, gestión de cuentas, etc.",
    "topText3": "Servicio al cliente de Telegram",
    "cItemText1": "Seguridad financiera",
    "cItemText2": "Imposible congelar, múltiples medidas de control de riesgos, aprobación estricta; su dinero está 100% seguro",
    "cItemText3": "Identificación inteligente",
    "cItemText4": "Comportamiento del usuario",
    "cItemText5": "Verificación de identidad",
    "tcItemText1": "Claridad contable",
    "tcItemText2": "Registros contables completos y claros; comprenda detalladamente el flujo de sus fondos",
    "tcItemText3": "Depósito instantáneo",
    "tcItemText4": "Detalles de ingresos y gastos",
    "tcItemText5": "Extracto de cuenta financiera"
  },
  "course": {
    "title1": "Autenticación de identidad",
    "title2": "Recepción y realización de pagos",
    "title3": "Tutorial de venta de monedas",
    "title4": "Tutorial de compra de monedas",
    "title5": "Tutorial de valorización",
    "title6": "Tutorial de retiro de monedas"
  },
  iosModal: {
    "text1": "Después de que la aplicación se haya descargado en el escritorio, debes seguir estos pasos para configurarla como confiable y que funcione correctamente:",
    "text2": "Vuelve a la pantalla de inicio y abre la configuración del sistema iOS",
    "text3": "Abrir",
    "text4": "Configuración - General -  Admón. de dispositivos y VPN",
    "text5": "Encuentra la aplicación recién instalada y haz clic en Configurar como Confiable"
  }
}