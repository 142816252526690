export default {
  Headers: {
    tab1: '首页',
    tab2: '教程',
    tab3: "商务合作",
    btntext: '扫码下载',
    tabT1: 'PayBet-首页',
    tabT2: 'PayBet-教程',
    tabT3: "PayBet-商务合作",
  },
  home: {
    title: '首页',
    dText1: 'Apple下载',
    dText2: 'Android下载',
    dText3: 'Web访问',
    text1: '飙速加值 畅行游戏',
    text2_1: '超级快充',
    text2_2: '极致体验',
    text3: '不受时间及国界限制，无论何时何地都能完成交易安全高效，不可追踪，',
    text3_1: '不受时间及国界限制，无论何时何地都能完成交易安全高效，不可追踪，无法冻结',
    text4: '无法冻结',
    text5: '友情提示:亲爱的PayBet用戶沒有收到款项，切记不要打币 如有操作失誤，损失自行承担',
    smText1: '私密货币',
    smText2: '为您提供高安全性的交易方式免除使用法币',
    smText3: '总资产',
    smText4: '快捷买币',
    smText5: '挂单出售',
    smText6: '我的挂单',
    smText7: '整单出售',
    smText8: '立即购买',
    wdText1: "稳定货币",
    wdText2: "汇率稳定性高，恒定全球法币币值",
    wdText3: "热点功能",
    wdText4: "收款",
    wdText5: "付款",
    wdText6: "转账",
    jdText1: "操作简单",
    jdText2: "下单、付款、打币，交易只需要三步即可完成",
    jdText3: "交易成功",
    jdText4: "您已成功出售",
    jdText5: "当前可用余额",
    jdText6: "交易编号",
    jdText7: "已成交",
    kjText1: "跨境交易",
    kjText2: "24/7交易无国界，加值一秒完成",
    kjText3: "卖方收款信息",
    kjText4: "收款人姓名",
    kjText5: "收款方式",
    kjText6: "收款账号",
    kjText7: "支付宝",
    kjText8: "买方付款信息",
    kjText9: "付款人姓名",
    kjText10: "付款方式",
    kjText11: "付款账号",
    kjText12: "王*尔",
    kjText13: "李*博",
    yyText1: "全面应用",
    yyText2: "极致体验，服务更丰富",
    yyText3: "银行卡号",
    qqText1: "全球部署服务器",
    qqText2: "不受时间及国界限制，无论何时何地都能完成交易，加值一秒完成",
  },
  footer: {
    text1: "下载PayBet开启全新之旅",
    text2: "不受时间及国界限制，无论何时何地都能完成交易安全高效，不可追踪，无法冻结",
    text3: "投诉邮箱",
    text4: "咨询邮箱"
  },
  cooperate: {
    topText1: '我们的服务',
    topText2: "面向商户提供全方位的聚合支付服务，满足商户线上一体化的支付需求。依托在支付领域深耕多年积累的技术与服务经验，为客户搭建全面账户体系，代付、代收、账务管理等账户管理服务。",
    topText3: "Telegram客服",
    cItemText1: "资金安全",
    cItemText2: "无法冻结，多道风控，严格审批，给您的资金百分百安全",
    cItemText3: "智能识别",
    cItemText4: "用户行为",
    cItemText5: "身份鉴定",
    tcItemText1: "账务清晰",
    tcItemText2: "账务完整、清晰、明了，详细掌握资金来龙去脉",
    tcItemText3: "资金秒到",
    tcItemText4: "收支明细",
    tcItemText5: "资金账单",
  },
  course: {
    title1: "实名认证",
    title2: "收付款",
    title3: "卖币教程",
    title4: "买币教程",
    title5: "加值教程",
    title6: "提币教程"
  },
  iosModal: {
    text1: "App在桌面下载完成后，必需按以下步骤操作，设置信任后才能正常使用:",
    text2: "返回手机桌面打开i0S系统设置",
    text3: "打开",
    text4: "设置-通用-VPN与设备管理",
    text5: "找到刚安装的应用，点击设置信任"
  }
}