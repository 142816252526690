export default {
  "Headers": {
    "tab1": "Página Inicial",
    "tab2": "Tutorial",
    "tab3": "Cooperação Comercial",
    "btntext": "Download",
    "tabT1": "PayBet-Página Inicial",
    "tabT2": "PayBet-Tutorial",
    "tabT3": "PayBet-Cooperação Comercial"
  },
  "home": {
    "title": "Página Inicial",
    "dText1": "Download para Apple",
    "dText2": "Download para Android",
    "dText3": "Acesso Web",
    "text1": "Acelere o Valor, Jogue sem Limites",
    "text2_1": "Carregamento Super Rápido",
    "text2_2": "Experiência Suprema",
    "text3": "Sem restrições de tempo ou fronteiras, as transações podem ser concluídas de forma segura e eficiente a qualquer momento e em qualquer lugar, sem rastreamento,",
    "text3_1": "Sem restrições de tempo ou fronteiras, as transações podem ser concluídas de forma segura e eficiente a qualquer momento e em qualquer lugar, sem rastreamento, impossível de congelar",
    "text4": "Impossível de congelar",
    "text5": "Dica Amigável: Caro usuário do PayBet, não envie moedas se não receber o pagamento. Se cometer algum erro, arque com as perdas por conta própria",
    "smText1": "Moeda Privada",
    "smText2": "Fornece um método de transação altamente seguro, eliminando o uso de moeda fiduciária",
    "smText3": "Ativos Totais",
    "smText4": "Compra Rápida de Moedas",
    "smText5": "Vender",
    "smText6": "Minhas Ordens Pendentes",
    "smText7": "Venda de Lote Inteiro",
    "smText8": "Compre Agora",
    "wdText1": "Moeda Estável",
    "wdText2": "Alta estabilidade nas taxas de câmbio, valor constante em moedas fiduciárias globais",
    "wdText3": "Recursos em Destaque",
    "wdText4": "Recebimento",
    "wdText5": "Pagamento",
    "wdText6": "Transferência",
    "jdText1": "Operação Simples",
    "jdText2": "Faça um pedido, pague, envie moedas - a transação pode ser concluída em apenas três passos",
    "jdText3": "Transação Bem-sucedida",
    "jdText4": "Você vendeu com sucesso",
    "jdText5": "Saldo Disponível Atual",
    "jdText6": "Número da Transação",
    "jdText7": "Concluído",
    "kjText1": "Transações Transfronteiriças",
    "kjText2": "Transações sem fronteiras 24/7, concluídas em um segundo",
    "kjText3": "Informações de Recebimento do Vendedor",
    "kjText4": "Nome do Recebedor",
    "kjText5": "Método de Recebimento",
    "kjText6": "Número da Conta do Recebedor",
    "kjText7": "Pix",
    "kjText8": "Informações de Pagamento do Comprador",
    "kjText9": "Nome do Pagador",
    "kjText10": "Método de Pagamento",
    "kjText11": "Número da Conta do Pagador",
    "kjText12": "José * Silva",
    "kjText13": "Ruben * Leitão",
    "yyText1": "Aplicação Abrangente",
    "yyText2": "Experiência Suprema",
    "yyText3": "Número do cartão",
    "qqText1": "Servidores Desdobrados Globalmente",
    "qqText2": "Sem restrições de tempo ou fronteiras, as transações podem ser concluídas a qualquer momento e em qualquer lugar, em um segundo"
  },
  "footer": {
    "text1": "Faça o Download do PayBet e Inicie uma Nova Jornada",
    "text2": "Sem restrições de tempo ou fronteiras, as transações podem ser concluídas de forma segura e eficiente a qualquer momento e em qualquer lugar, sem rastreamento, impossível de congelar",
    "text3": "E-mail de Reclamação",
    "text4": "E-mail de Consulta"
  },
  "cooperate": {
    "topText1": "Nossos Serviços",
    "topText2": "Oferecemos serviços abrangentes de pagamento agregado para comerciantes, atendendo às necessidades de pagamento online integrado dos comerciantes. Com base em anos de experiência técnica e de serviço acumulada no campo de pagamentos, construímos um sistema abrangente de contas para os clientes, incluindo serviços de pagamento e recebimento em nome deles, gestão de contas, etc.",
    "topText3": "Atendimento ao Cliente no Telegram",
    "cItemText1": "Segurança Financeira",
    "cItemText2": "Incapaz de congelar, controle de risco multicanal, aprovação rigorosa, seus fundos estão 100% seguros",
    "cItemText3": "Identificação Inteligente",
    "cItemText4": "Comportamento do Usuário",
    "cItemText5": "Verificação de Identidade",
    "tcItemText1": "Transparência Financeira",
    "tcItemText2": "As contas são completas, claras e claras, e os detalhes dos fundos são compreendidos em detalhes",
    "tcItemText3": "Pagamento Rápido",
    "tcItemText4": "Detalhes de Entradas e Saídas",
    "tcItemText5": "Extrato de Conta Financeira"
  },
  "course": {
    "title1": "Autenticação Real",
    "title2": "Recebimento e Pagamento",
    "title3": "Tutorial de Venda de Moedas",
    "title4": "Tutorial de Compra de Moedas",
    "title5": "Tutorial de Valorização",
    "title6": "Tutorial de Retirada de Moedas"
  },
  iosModal: {
    "text1": "Depois que o app for baixado na área de trabalho, você deve seguir estas etapas para defini-lo como confiável para que ele funcione corretamente:",
    "text2": "Volte para a tela inicial e abra as configurações do sistema iOS",
    "text3": "Abrir",
    "text4": "Configurações - Geral - Gerenciamento de VPN Dispositivo",
    "text5": "Encontre o aplicativo recém-instalado e clique em Definir como Confiável"
  }
}